/*global $:false*/
/*global escape: true */
/*jslint browser: true*/

//check for the orientation event and bind accordingly
if (window.DeviceOrientationEvent) {
    window.addEventListener('orientationchange', WindowSize, MainNavMarkup, moveElements, moveCTAPanel, runFocusPoint, false);
}

function SayThanksComments() {
    if ($('body.ExtraSmallDevices, body.Default').length) {
        //console.log("Hide Comments");
        if ($(this).find('.heathcare-hero-content #HiddenComments').length) {
            //no nothing
            //console.log('do nothing');
        } else {
            //console.log("else");
            if ($('#HiddenComments').length) {
                // do nothing
            } else {
                $('.healthcare-hero-item:gt(3)').wrapAll('<div id="HiddenComments" role="region" aria-labelledby="HiddenCommentsButton" hidden />');
            }
            
            if ($('#HiddenComments').length && $('#HiddenCommentsButton').length) {
                //do not add button
            } else if ($('#HiddenComments').length) {
                $('<h3 class="HiddenCommentsButtonWrap"><button id="HiddenCommentsButton" aria-controls="HiddenComments" aria-expanded="false">Show More Comments</button></h3>').insertBefore('.heathcare-hero-content #HiddenComments');
            }
        }

        $('#HiddenCommentsButton').on('click', function() {
            $('#HiddenComments').addClass('Active').removeAttr('hidden');
            // Remove active state from all the buttons
            $(this).attr({
                'aria-expanded': 'true'
            });
            $(this).parent().addClass('Active');
            return false;
        });

        $('#HiddenCommentsButton').on('keydown', function(event) {
            if ((event.which === 13) || (event.which === 32)) {
                //console.log("Enter or space bar pressed");
                $(this).click();
                return false;
            }
        });
    } else {
        $('h3.HiddenCommentsButtonWrap').remove();
        $('#HiddenComments .healthcare-hero-item').unwrap();
    }
}

function WindowSize() {
    // Do not apply media breakpoint classes on the fixed width template
    // If IE8 add class of Fixed Width to the body
    if ($('html.ie8').length > 0) {
        $('body').addClass('FixedWidth Default');
    }

    if (!$('body.FixedWidth').length) {
        var w = Math.max($(window).width(), window.innerWidth),
        
        type = 'Default';

        if (w >= 1200) {
            type = 'LargeDevices';
        } else if (w >= 992) {
            type = 'MediumDevices';
        } else if (w >= 768) {
            type = 'SmallDevices';
        } else if (w < 768) {
            type = 'ExtraSmallDevices';
        }

        // change the css file of the tag with id="stl" and rel="stylesheet"
        $('body').removeClass('ExtraSmallDevices SmallDevices MediumDevices LargeDevices Default').addClass(type);
    }
}

//bind to resize
$(window).resize(function () {
    WindowSize();
    MainNavMarkup();
    moveElements();
    moveCTAPanel();
    runFocusPoint();

    if ($('body.SayThanks').length) {
        SayThanksComments();
    }

    //reset height to 0
    $('.Home #ColumnTwo div.cmspanel .cmsPanelContent, .Home #ColumnTwo div.cmspanel .DarkBluePanelNoImage').css('min-height', '0');
    $('#Footer .Container > div').css('min-height', '0');

    // run equalHeights Script on window resize
    equalHeight($('.Home #ColumnTwo div.cmspanel .cmsPanelContent, .Home #ColumnTwo div.cmspanel .DarkBluePanelNoImage'));
    equalHeight($('.FixedWidth #Footer .Container > div, .LargeDevices #Footer .Container > div, .MedicumDevices #Footer .Container > div'));

    if ($("body.Campaign.LargeDevices, body.Campaign.MediumDevices, body.Campaign.SmallDevices").length) {
        // equalHeight($(".Campaign #ColumnTwo div.cmspanel .cmsPanelContent .BluePanelWithImage, .Campaign #ColumnTwo div.cmspanel .cmsPanelContent .DarkBluePanelNoImage"));
        equalHeight($('#PanelCareersCampaignTemplateBlueBox1 .cmsPanelContent, #PanelCareersCampaignTemplateBlueBox2 .cmsPanelContent'));
        equalHeight($('#PanelCareersCampaignTemplateBlueBox3 .cmsPanelContent, #PanelCareersCampaignTemplateBlueBox4 .cmsPanelContent'));
        equalHeight($('#BelowMainText .cards.four.RowOne li'));
        equalHeight($('#BelowMainText .cards.four.RowTwo li'));
        equalHeight($('#BelowMainText .cards.four.RowThree li'));
        equalHeight($('#BelowMainText .cards.three.RowOne li'));
        equalHeight($('#BelowMainText .cards.three.RowTwo li'));
        equalHeight($('#BelowMainText .cards.three.RowThree li'));
    }
    
    // Open up video in modaal on profile details
    if (IsSmallDevice()) {
        $("#DrDetail #Practice").insertAfter("#DrDetail .QuickInfo .Locations");
    } else {
        $("#DrDetail #Practice").insertAfter("#DrDetail .QuickInfo .Designations h1");
    }
});

$(document).ready(function () {

    $('body.LocationProfile .locationSecondary.cmspanel').prependTo('.SecondaryNavigation');
    
    //modDoctorPhotos();
    WindowSize();
    FlyOut();
    MainNavMarkup();
    moveElements();
    moveCTAPanel();
    runFocusPoint();
    SayThanksComments();

    //adding a class to allow equal height script to be applied.
    $('.Home #ColumnTwo div.cmspanel .cmsPanelContent, .Home #ColumnTwo div.cmspanel .DarkBluePanelNoImage').addClass('EqualHeight');

    $( ".Campaign #BelowMainText .cards li img" ).wrap( "<div class='ImageContainer'></div>");

    $("p.MoreLink").each(function () {
        if ($(this).is(":empty")) {
            $(this).remove();
        }
    });

    if ($('.flexslider').length) {
        $(".flexslider").flexslider({
            animation: "fade",
            slideshowSpeed: 8000,
            animationLoop: true,
            pauseOnHover: true,
            randomize: true,
            pausePlay: true,
        });
    }

    /* MainNav
    ----------------------------------------------------------------- */
    $('#MainNav h2').insertBefore('#MainNav');

    /* Mobile Menu Toggle
    ----------------------------------------------------------------- */
    $('.SearchTrigger, #CloseSearch').click(function () {
        $('#SearchContainer').toggleClass('Open');
        $(this).toggleClass('Active');
        return false;
    });

    $(document).on("click", '.MenuButton, .CloseButton', function () {
        $(this).toggleClass('Active');
        $('#BodyWrap').toggleClass('OpenRight');
        $('#menu').toggleClass('Active');
        $('body').toggleClass('LightBlue');
        return false;
    });

    if (!$('#BHCTemplate').length) {

        $(document).on('click', '#NavigationWrapper #MainNav li.Last, #WantWrapper h2.Back', function () {
            $('#menu nav').toggleClass('OpenRightAgain');
            $('#WantWrapper').toggleClass('Active');
            return false;
        });
    }
    
    /* Sticky Nav
    ----------------------------------------------------------------- */
    if (navigator.userAgent.match(/silk/i)) {
        // do nothing
    }
    else {
        if (($(document).height() - 185) > $(window).height()) {
            if($('#BreadcrumbWrap').length > 0 && $('#StickyNav').length > 0) {
                var stickyNavTop = $('#BreadcrumbWrap').offset().top;
                var stickyNavHeight = $('#StickyNav').outerHeight();
                var stickyNav = function () {
                    var scrollTop = $(window).scrollTop();
                    if ($(window).width() > 1199) {
                        if (scrollTop > stickyNavTop) {
                            $('#StickyNavWrap').css('height', stickyNavHeight); //adding height of original sticky nav to wrapper to hold height of page when scrolling
                            $('#StickyNav').addClass('Fixed');
                            $('#StickyNav #SearchContainer .ImgButton').attr('src', 'https://www.bryanhealth.com/imgs/icons/search-button-white.png');                         
                        }
                        else {
                            $('#StickyNav').removeClass('Fixed');
                            $('#StickyNav #SearchContainer .ImgButton').attr('src', 'https://www.bryanhealth.com/imgs/icons/search-button.gif');
                            $('#StickyNavWrap').removeAttr('style');
                        }
                    }
                    else if (IsSmallDevice()) {
                        $('#StickyNav #SearchContainer .ImgButton').attr('src', 'https://www.bryanhealth.com/imgs/icons/search-button-white.png');
                        $('#StickyNav').removeClass('Fixed');
                    } else {
                        $('#StickyNav').removeClass('Fixed');
                        $('#StickyNav #SearchContainer .ImgButton').attr('src', 'https://www.bryanhealth.com/imgs/icons/search-button.gif');
                    }
                };
                    //do not want this to run on location child pages
                    if ($('body.LocationChild').length) {
                        //do nothing
                    } else {
                        stickyNav();
                        $(window).scroll(function () { stickyNav(); });
                    }
            }
        }
    }

    // Site Search Icon
    var WhiteSearchIcon = 'https://www.bryanhealth.com/imgs/icons/search-button-white.png';
    var BlueSearchIcon = 'https://www.bryanhealth.com/imgs/icons/search-button.gif';

    $('.ExtraSmallDevices #SearchContainer input.ImgButton').attr('src', WhiteSearchIcon);
    $('.SmallDevices #SearchContainer input.ImgButton').attr('src', WhiteSearchIcon);
    $('.MediumDevices #SearchContainer input.ImgButton').attr('src', BlueSearchIcon);
    $('.LargeDevices #SearchContainer input.ImgButton').attr('src', BlueSearchIcon);

    $("#FullWidthPanel #SmartPanelLocations li dd.Phone").each(function () {
        $(this).parent().append($(this));
    });

    // Find the number of panels in the FullWidthPanel Slot
    if ($('#FullWidthPanel').length) {
        var NumberOfPanels = ($('#FullWidthPanel div.cmspanel').length);
        //alert(NumberOfPanels);
        if (NumberOfPanels === 2) {
            $('body:not(.SayThanks) #FullWidthPanel div.cmspanel').addClass('TwoCol');
            $('body:not(.SayThanks) #FullWidthPanel div.cmspanel:first').addClass('LeftColumn');
        }
    }

    equalHeight($('.LargeDevices #Footer .Container > div, .MediumDevices #Footer .Container > div, .FixedWidth #Footer .Container > div'));

    // Formatting Physician Search Box On Results and Search
    $("#DrResults .DrSearch label:contains('Specialties')").parent().addClass('Specialties');
    $("#DrResults .DrSearch fieldset").removeClass("BlockLabels");
    $("#DrResults .DrSearch .PhysicianFirst, #DrResults .DrSearch .PhysicianLast").removeClass("Column");
    $("#DrResults .DrSearch .CheckRadio, #DrResults .DrSearch legend").remove();
    $("#DrResults .DrSearch .PhysicianFirst, #DrResults .DrSearch .PhysicianLast, #DrResults .DrSearch .Specialties, #DrResults .DrSearch .City, #DrResults .DrSearch .ZipCode, #DrResults .DrSearch .Gender").addClass("ClearFix").wrapAll('<div id="Advanced">');
    $("#DrResults .DrSearch #Advanced").addClass("ClearFix");
    $("#DrResults .SortContainer").first().remove();
    
   /*if ($('#DrResults .DrList').length > 0) {
        $('#DrResults .DrList > li > dl').each(function () {
            $(this).find('dt:first').before($(this).find('dd.Picture'));
            $(this).find('dt:first').addClass('ResultsDocName');
            $(this).find('.ResultsDocName,.Specialties,.Locations').wrapAll('<div class="DocResultsDetails" />');
            $(this).find('.ExtraInfo').after($(this).find('.DocResultsDetails'));
        });
        $('#DrResults .DrList > li div.ExtraInfo').find('.AppLink').attr('target', '_blank');

        $('#DrResults .DrList .Profile').each(function () {
            $(this).find('dd.Specialties ul').wrap('<div class="SpecialtiesHeader"></div>');
            $(this).find('dd.Specialties .SpecialtiesHeader').prepend('<strong>Specialties</strong>');
            
            $(this).find('dd.Locations dl').wrap('<div class="LocationsHeader"></div>');
            $(this).find('dd.Locations .LocationsHeader').prepend('<strong>Locations</strong>');
            
            $('#DrResults .ExtraInfo').find('br').remove();
        });
    }*/

    // Resize photo for doctors list
    /*function modDoctorPhotos() {
        $('#DrResults ul.DrList dd.Picture img').attr('src', function (index, attr) {
            return attr.replace("small", "medium");
        });
        $("#DrResults .DrList dd.Picture a img").wrap("<div class='DoctorPictureWrapper'/>");
        $("#DrResults .DrList dd.Picture a img").attr("style", "width: 128px;");
    }*/
    
    // Showing Logos on Physician Detail
    if ($("#DrDetail .Promotions li").length) {
        $("#DrDetail .Promotions li").each(function() {
            if ($(this).html().indexOf("Bryan Health Connect") >= 0) {
                $(this).remove();
            } else if ($(this).html().indexOf("Bryan Heart") >= 0) {
                $(this).empty();
                $("<img>", {
                    src: "/imgs/logos/bryan-heart.png",
                    alt: "Bryan Heart"
                }).appendTo($(this));                
            } else if ($(this).html().indexOf("Bryan Physician Network") >= 0) {
                $(this).empty();
                $("<img>", {
                    src: "/imgs/logos/bryan-physician-network.png",
                    alt: "Bryan Physician Network"
                }).appendTo($(this));
            } else if ($(this).html().indexOf("Crete") >= 0) {
                $(this).empty();
                $("<img>", {
                    src: "/imgs/logos/bryan-crete-area-medical-center.png",
                    alt: "Bryan Crete Area Medical Center"
                }).appendTo($(this));
            } else if ($(this).html().indexOf("Merrick Medical Center") >= 0) {
                $(this).empty();
                $("<img>", {
                    src: "/imgs/logos/bryan-merrick-medical-center.png",
                    alt: "Bryan Merrick Medical Center"
                }).appendTo($(this));
            }
        });
    }
    // Showing Logos on Physician Search
    if ($("#DrResults .DrList").length) {
        $("#DrResults .DrList .ExtraInfo li").each(function() {
            if ($(this).html().indexOf("Bryan Health Connect") >= 0) {
                $(this).remove();
            } else if ($(this).html().indexOf("Bryan Heart") >= 0) {
                $(this).empty();
                $("<img>", {
                    src: "/imgs/logos/bryan-heart.png",
                    alt: "Bryan Heart"
                }).appendTo($(this));                
            } else if ($(this).html().indexOf("Bryan Physician Network") >= 0) {
                $(this).empty();
                $("<img>", {
                    src: "/imgs/logos/bryan-physician-network.png",
                    alt: "Bryan Physician Network"
                }).appendTo($(this));
            } else if ($(this).html().indexOf("Crete") >= 0) {
                $(this).empty();
                $("<img>", {
                    src: "/imgs/logos/bryan-crete-area-medical-center.png",
                    alt: "Bryan Crete Area Medical Center"
                }).appendTo($(this));                
            }  else if ($(this).html().indexOf("Merrick Medical Center") >= 0) {
                $(this).empty();
                $("<img>", {
                    src: "/imgs/logos/bryan-merrick-medical-center.png",
                    alt: "Bryan Merrick Medical Center"
                }).appendTo($(this));
            }  else if ($(this).html().indexOf("Kearney Regional Medical Center") >= 0) {
                $(this).empty();
                $("<img>", {
                    src: "/imgs/logos/bryan-kearney-regional.png",
                    alt: "Bryan Kearney Regional Medical Center"
                }).appendTo($(this));
            }
        });
    }
    
    // Open up video in modaal on profile details
    if ($("#DrDetail .QuickInfo .Designations a.HasVideo").length) {
        $("#DrDetail .QuickInfo .Designations a.HasVideo").each(function() {
            var url = $(this).attr("href").split('.com/watch?v=');
            $(this).attr('href', '//www.youtube-nocookie.com/embed/' + url[1] + '?autoplay=1');
            $(this).modaal({
                type: 'video'
            });
        });
    }
    
    // Show Bottom Panel On Bryan Affiliated Physicians Only
    if ($("#DrResults").length) {
        $("#DrResults .Tabnav li").each(function () {
            if ($(this).hasClass("Tabactive")) {
                if ($("a", this).html().indexOf("Bryan Health Connect") >= 0) {
                    $("#FullWidthPanel").show();
                } else {
                    $("#FullWidthPanel").hide();
                }
            }
        });
    }
    
    /* Providers API
    ----------------------------------------------------------------- */
    /* Pull Doctor Video Link into Dr Search Results and Open Video in LightBox */
   /* setTimeout(function () {
        $('#DrResults .HasVideo strong').each(function (i, el) {
            var drURL = $(this).closest('li.Profile').find('dl dt a').attr('href');
            var drID = drURL.split('id=')[1]; 
            // so we don't see a blink
            $(el).hide();

            $.getProviderById({
                providerId: drID,
                fields    : "Videos"
            }, function (data) {
                addLink(el, data.Value.Videos[0]);
                $(el).show();
            });
        });
    }, 50);

    function addLink(el, url) { 
        url = url.split('.com/watch?v=');
        $(el).wrapInner('<span />').text("Meet the Doctor (Video)");
        $(el).wrapInner('<span />').wrapInner('<a class="ProviderVideo" href="//www.youtube-nocookie.com/embed/' + url[1] + '?autoplay=1" />');

        $('a.ProviderVideo', el).fancybox({
            'transitionIn' : 'elastic',
            'transitionOut': 'elastic',
            'autoscale'    : true,
            'overlayColor' : '#1f1f1f',
            'titleShow'    : true,
            'titlePosition': 'outside',
            'type'         : 'iframe'
        });
    }*/
    
    $('a.ProviderVideo').modaal({
        type: 'video'
    });

    // Move Find a location search above fake tabs
    $('#LocationsSearch .SearchBox').insertBefore('.cmsPageContent .LocationsTabs').addClass('LocationsSearch');
    $('.FormContainer .CalendarList .SearchBox').addClass('ClearFix');
    $('.FormContainer .CalendarList div.categoryDropDownDiv').addClass('ClearFix');

    // Keeps accordions from jumping to the top of the page when accordion is really long
    // Reason for this hack: sticky nav was covering up the top of the accordion
    $('.Accordion .Trigger').each(function () {
        $(this).mouseup(function () {
            $('html, body').animate({ scrollTop: $(window).scrollTop() });
        });
    });

    /*  News (Blog) Smart Panel
   ----------------------------------------------------------------- */
    $('#SmartPanelNewsRecent ul.PageList .Thumbnail img').each(function() {
        $(this).attr('src', $(this).attr('src').replace('?size=small',''));
    });
}); /* End Doc ready */

function IsSmallDevice() {
    if ($("body.ExtraSmallDevices").length > 0 || $("body.SmallDevices").length > 0) {
        return true;
    } else {
        return false;
    }
}

function moveElements() {


    $('.LargeDevices, .MediumDevices').each(function () {
        $('#GeolocationControl').insertAfter('.SearchTrigger');
    });
    $('.SmallDevices, .ExtraSmallDevices').each(function () {
        $('#GeolocationControl').insertAfter('.SecondaryNav');
    });
    $('#StickyNav').removeClass('Fixed');
    
    if ($("body.MediumDevices").length || $("body.LargeDevices").length || $("body.FixedWidth").length) {

        // Run equal Heights script for Footer 
        equalHeight($('#Footer .Container > div'));

        // Remove style from attr on desktop
        $('div#ProximityNavWrap1').removeAttr('style');
    }

    if ($("#Breadcrumbs").length) {
        if (IsSmallDevice()) {
            $('#Breadcrumbs ol li').addClass('MobileBreadcrumb');
        } else {
            $('#Breadcrumbs ol li').removeClass('MobileBreadcrumb');
        }
    }

    if (IsSmallDevice()) {
        // Banner add border
        if ($("#Banner").length) {
            if (isEmpty($("#Banner"))) {
                $("#BreadcrumbWrap").css("border-top", "1px solid #d2cac2");
            } else {
                $("#BreadcrumbWrap").css("border-top", "none");
            }
        }
        $('#StickyNav #SearchContainer .ImgButton').attr('src', 'https://www.bryanhealth.com/imgs/icons/search-button-white.png');

        // Small and ExtraSmall Deives -- add aria for jumpNav on Location Profile
        $('#JumpLinksToggle').attr('aria-expanded', 'false').attr('aria-controls', 'JumpLinks').removeAttr('Class');
        $('#JumpLinks').removeAttr('aria-hidden').removeAttr('style');

    } else {
        // Medium, Large and XL devices
        $('#StickyNav #SearchContainer .ImgButton').attr('src', 'https://www.bryanhealth.com/imgs/icons/search-button.gif');
         
        // remove aria for jumpNav on Location Profile since it's not needed on large sizes
        $('#JumpLinksToggle').removeAttr('aria-expanded').removeAttr('aria-controls').removeClass('Active');
        $('#JumpLinks').removeAttr('aria-hidden').removeAttr('style');
    }

    // adding breaks in main nav
    if ($("body.MediumDevices").length) {
        $('#MainNav ul li span').each(function () {
            if ($(this).html() === "Find a Doctor") {
                $(this).html("Find a<br>Doctor");
            }
            if ($(this).html() === "Care &amp; Treatment") {
                $(this).html("Care &amp;<br>Treatment");
            }
            if ($(this).html() === "Locations &amp; Directions") {
                $(this).html("Locations &amp;<br>Directions");
            }
            if ($(this).html() === "Patients &amp; Visitors") {
                $(this).html("Patients &amp;<br>Visitors");
            }
            if ($(this).html() === "Classes &amp; Events") {
                $(this).html("Classes &amp;<br>Events");
            }
            if ($(this).html() === "I Want To") {
                $(this).html("I Want<br>To");
            }
        });
    } else {
        $('#MainNav ul li span').each(function () {
            if ($(this).html() === "Find a<br>Doctor") {
                $(this).html("Find a Doctor");
            }
            if ($(this).html() === "Care &amp;<br>Treatment") {
                $(this).html("Care &amp; Treatment");
            }
            if ($(this).html() === "Locations &amp;<br>Directions") {
                $(this).html("Locations &amp; Directions");
            }
            if ($(this).html() === "Patients &amp;<br>Visitors") {
                $(this).html("Patients &amp; Visitors");
            }
            if ($(this).html() === "Classes &amp;<br>Events") {
                $(this).html("Classes &amp; Events");
            }
            if ($(this).html() === "I Want<br>To") {
                $(this).html("I Want To");
            }
        });
    }

    /* Columns */
    if ($('body.Home').length || $('body.Campaign').length) {
        // don't move columns on Homepage Template
    } else {
        $('.ExtraSmallDevices #ColumnOne').insertAfter('#ColumnTwo');
        $('.ExtraSmallDevices #ColumnThree').insertAfter('#ColumnOne');
        $('.SmallDevices #ColumnTwo').insertBefore('#ColumnOne');
        $('.SmallDevices #ColumnThree').insertAfter('#ColumnOne');
        $('.MediumDevices #ColumnOne').insertBefore('#ColumnTwo');
        $('.MediumDevices #ColumnThree').appendTo('#ColumnOne');
        $('.LargeDevices #ColumnThree').insertAfter('#ColumnTwo');
        $('.LargeDevices #ColumnOne').insertBefore('#ColumnTwo');
    }

        //Prox Nav
        if (window.location.href.indexOf("maternity") > -1) {
            $("body.ExtraSmallDevices div#ProximityNavWrap").insertBefore("div#Banner");
            $("body.SmallDevices div#ProximityNavWrap").insertBefore("div#Banner");
            $('body.MediumDevices div#ProximityNavWrap').insertBefore('#LeftColumnPanels');
            $('body.LargeDevices div#ProximityNavWrap').insertBefore('#LeftColumnPanels');
        } else {
            $('body.ExtraSmallDevices div#ProximityNavWrap').insertAfter('#BreadcrumbWrap');
            $('body.SmallDevices div#ProximityNavWrap').insertAfter('#BreadcrumbWrap');
            $('body.MediumDevices div#ProximityNavWrap').insertBefore('#LeftColumnPanels');
            $('body.LargeDevices div#ProximityNavWrap').insertBefore('#LeftColumnPanels');
        }


    // HomePage Banner 
    //wrapping img with .focuspoint, but first checking to see if it exist, if it does do nothing if not wrap the data-focus variables
    if ($('#HomeBanner .focuspoint').length > 0) {
        // do nothing
    } else {
        // Do NOT call focuspoint if the banner is a countdown banner
        if ($('body.ExtraSmallDevices #HomeBanner .CountdownBanner').length) {
            //do nothing 
        } else {        
            $('body.ExtraSmallDevices #HomeBanner .slick-list img').wrap('<div class="focuspoint" data-focus-x="-0.43" data-focus-y="0.04" data-focus-w="1275" data-focus-h="525" />');
        }
    }

    // if ($('body.ExtraSmallDevices #HomeBanner').length) {
    //     $(this).focusPoint();
    // }
    
    // Force MainNavWrapper to have a min height equal to the MainNav ul
    // Reason:  The MainNav has a different height in each browser, if the MainNavWrapper background it too tall or too short it looks odd
    // this fixes the weird 1px off space across the browsers
    //var MainNavHeight = $('#MainNav ul').height();
    //$('#MainNavWrapper').css('min-height', MainNavHeight);
}

// ----------- AFTER PAGE LOAD ------------------//
$(window).on("load", function () {
    // run equalHeights Script on page load    
    equalHeight($(".Home #ColumnTwo div.cmspanel .cmsPanelContent, .Home #ColumnTwo div.cmspanel .DarkBluePanelNoImage"));
    if ($("body.Campaign.LargeDevices, body.Campaign.MediumDevices, body.Campaign.SmallDevices").length) {
        // equalHeight($(".Campaign #ColumnTwo div.cmspanel .cmsPanelContent .BluePanelWithImage, .Campaign #ColumnTwo div.cmspanel .cmsPanelContent .DarkBluePanelNoImage"));
        equalHeight($('#PanelCareersCampaignTemplateBlueBox1 .cmsPanelContent, #PanelCareersCampaignTemplateBlueBox2 .cmsPanelContent'));
        equalHeight($('#PanelCareersCampaignTemplateBlueBox3 .cmsPanelContent, #PanelCareersCampaignTemplateBlueBox4 .cmsPanelContent'));
        equalHeight($('#BelowMainText .cards.four.RowOne li'));
        equalHeight($('#BelowMainText .cards.four.RowTwo li'));
        equalHeight($('#BelowMainText .cards.four.RowThree li'));
        equalHeight($('#BelowMainText .cards.three.RowOne li'));
        equalHeight($('#BelowMainText .cards.three.RowTwo li'));
        equalHeight($('#BelowMainText .cards.three.RowThree li'));
    }
});

function isEmpty(el) {
    return !$.trim(el.html());    
}

function equalHeight(group) { //make columns equal heights
    var tallest = 0;
    group.each(function () {
        var thisHeight = $(this).height();
        if (thisHeight > tallest) {
            tallest = thisHeight;
        }
    });
    group.css('min-height', tallest);
}

function FlyOut() {
    $('.Flyouts .Flyout').each(function (i) {
        if ($(this).find('.BasicPanel').html().trim().length) {
            $(this).appendTo($('#MainNav > ul > li').eq(i));
        }
    });
    $('.Flyouts').remove();
}

function MainNavMarkup() {
    // Remove link around "I want to"
    if($('#MainNav li.Last > a span').text() === "I Want To") {
        $('#MainNav li.Last > a').attr('href', '#');
    }

    // On Mobile Devices
    if ($('body.LargeDevices').length === 0 && $('body.MediumDevices').length === 0 && $('body.FixedWidth').length === 0) {

        // If Navigation wrapper doesn't have a menu - move it here from the header
        if (!$('#NavigationWrapper #menu').length) {
            $('#menu').appendTo('#NavigationWrapper');
        }

        if ($('body#EpicTemplate').length) {
            //do nothing
        } else {
            // If WantWrapper doesn't have a menu - move it here from the header
            if (!$('#WantWrapper .WantMenu').length) {
                $('.WantMenu').appendTo('#WantWrapper');
            }

            if (!$('#WantWrapper .WantMenu h2.Back').length) {
                $('<h2 class="Back"><span class="fa fa-angle-left"></span>&nbsp;I Want To</h2>').prependTo('.WantMenu .cmsPanelContent');
            }
            
            // add right arrow to I want to menu if it doesn't already exist
            if (!$('#MainNav ul > li.Last > a span.fa-angle-right').length) {
                $('#MainNav ul > li.Last > a').append('<span class="fa fa-angle-right"></span>');
            }
        }
        
        // add class Close button to MainNav h2 if it doesn't already exist
        if (!$('#menu nav h2.CloseButton').length) {
            $('#menu nav h2').removeClass('Hide').addClass('CloseButton');
        }

        // add left arrow to MainNav h2 if it doesn't already exist
        if (!$('#menu nav h2 span.fa-angle-left').length) {
            $('#menu nav h2').prepend('<span class="fa fa-angle-left"></span>');
        }

        // Move Secondary Navigation into mobile menu if it doesn't already exists
        if (!$('#NavigationWrapper .SecondaryNav').length) {
            $('.SecondaryNavigation .cmspanel').addClass('SecondaryNav').insertAfter('#NavigationWrapper #MainNav');
        }
    }
    else {
        // Move Secondary Navigation Back
        $('#NavigationWrapper #menu .SecondaryNav').appendTo('.SecondaryNavigation').removeClass('SecondaryNav');

        // Move Main Nav back into place If Navigation wrapper doesn't have a menu - move it here from the header
        $('#NavigationWrapper #menu').appendTo('#MainNavWrapper');
        $('#MainNavWrapper h2').removeClass('CloseButton').addClass('Hide');
        $('#MainNavWrapper li.Last span.fa-angle-right').remove();

        // Move I want to menu back
        $('#WantWrapper .WantMenu').appendTo('#MainNav li.Last');
    }
}

function moveCTAPanel() {
    /* Call To Action */
    // If Move-CTA-Here exisits - move the CTA to that spot
    // If Move-CTA-Here does not exists - check to see if there is an h1 imediately followed by an h2 and move it after that h2
    // If no h2 exists imediately after an h2, move CTA after the h1 instead
    // If no Move-CTA-Here, H1 or H2 exists - move CTA to the top of the cmsPageContent
    var InsertHere = '';
    if ($('.LeftCol #CallToAction, .NoCols #CallToAction').length > 0) {
        if ($('.Move-CTA-Here:first').length > 0) {
            $('#CallToAction').appendTo('.Move-CTA-Here');
        }
        else if ($('.cmsPageContent h1:first').length > 0) {
            if ($('.cmsPageContent  h1:first').next('h2').length > 0) {
                InsertHere = $('.cmsPageContent h1:first').next('h2');
                $('#CallToAction').insertAfter(InsertHere);
            }
            else {
                InsertHere = $('.cmsPageContent h1:first');
                $('#CallToAction').insertAfter(InsertHere);
            }
        } else {
            $('#CallToAction').prependTo('.cmsPageContent');
        }
    } else {
        if ($('.MediumDevices .Move-CTA-Here:first, .SmallDevices .Move-CTA-Here:first, .ExtraSmallDevices .Move-CTA-Here:first').length > 0) {
            $('.MediumDevices #ColumnThree #CallToAction').appendTo('.MediumDevices .Move-CTA-Here');
            $('.SmallDevices #ColumnThree #CallToAction').appendTo('.SmallDevices .Move-CTA-Here');
            $('.ExtraSmallDevices #ColumnThree #CallToAction').appendTo('.ExtraSmallDevices .Move-CTA-Here');
        }
        else if ($('.MediumDevices .cmsPageContent h1:first, .SmallDevices .cmsPageContent h1:first, .ExtraSmallDevices .cmsPageContent h1:first').length > 0) {
            if ($('.MediumDevices .cmsPageContent  h1:first, .SmallDevices .cmsPageContent h1:first, .ExtraSmallDevices .cmsPageContent h1:first').next('h2').length > 0) {
                InsertHere = $('.cmsPageContent h1:first').next('h2');
                $('.MediumDevices #ColumnThree #CallToAction, .SmallDevices #ColumnThree #CallToAction, .ExtraSmallDevices #ColumnThree #CallToAction').insertAfter(InsertHere);
            }
            else {
                InsertHere = $('.cmsPageContent h1:first');
                $('.MediumDevices #ColumnThree #CallToAction, .SmallDevices #ColumnThree #CallToAction, .ExtraSmallDevices #ColumnThree #CallToAction').insertAfter(InsertHere);
            }
        } else {
            $('.MediumDevices #ColumnThree #CallToAction, .SmallDevices #ColumnThree #CallToAction, .ExtraSmallDevices #ColumnThree #CallToAction').prependTo('.cmsPageContent');
        }

        $('.LargeDevices .cmsPageContent #CallToAction').prependTo('#ColumnThree');
    }
}

function runFocusPoint() {
    // check if focuspoint function defined
    if (typeof $().focusPoint === "function") {
        // Banner Focus Point on Interior Pages
        // only run focuspoint on small and xs screen sizes
        //if ($("body").hasClass("ExtraSmallDevices") || $("body").hasClass("SmallDevices") || $("body").hasClass("MediumDevices")) {
            // console.log("small device");
            $('#Banner .focuspoint').focusPoint({
                reCalcOnWindowResize: true,
                throttleDuration: 100,
            });
        //} else {
            // do nothing
            //console.log('not small device');
        //}

        // Do NOT call focuspoint if the banner is a countdown banner
        if ($('body.ExtraSmallDevices #HomeBanner .CountdownBanner').length) {
            //do nothing 
        } else {
            $('body.ExtraSmallDevices #HomeBanner').focusPoint({
                reCalcOnWindowResize: true,
                throttleDuration: 100,
            });
        }
        }
}